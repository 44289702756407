import React from 'react'
import { PatientContextProvider } from './services/context/patients/patientContextServices'
import { HeaderPageContextProvider } from './services/context/common/headerPageContextServices'
import { AuthContextProvider } from './services/context/common/authContextService'
import { RepositoryProvider } from './services/repositories/provider'
import { repository } from './services/repositories'

const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <AuthContextProvider>
    <PatientContextProvider>
      <HeaderPageContextProvider>
        <RepositoryProvider repository={repository}>
          {children}
        </RepositoryProvider>
      </HeaderPageContextProvider>
    </PatientContextProvider>
  </AuthContextProvider>
)

export default AppProviders
