import Axios, { AxiosRequestConfig } from 'axios'
import { authService } from './authService'
import { IParametersServices } from 'interfaces'

interface IEndPointCaller<TEntity> {
    uri: string,
    parameters?: TEntity
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    data?: any
    pagination?: IParametersServices
}

export const endpointCaller = async <TEntity>(props: IEndPointCaller<TEntity>) => {
  authService()
  const { uri, parameters, method, data, pagination } = props
  const paginationURI = method.toUpperCase() === 'GET' ? { limit: !pagination?.limit ? 999 : pagination.limit, offset: !pagination?.offset ? 0 : pagination.offset } : {}
  const url = formatterURL(`${process.env.REACT_APP_URI_BASE}/${uri}`, parameters)

  const config: AxiosRequestConfig = {
    method,
    url,
    params: { ...parameters, ...paginationURI },
    data
  }
  const response = await Axios(config)
  return response.data
}

const formatterURL = (baseUrl: string, requestParams: any) => {
  return baseUrl.replace(/:(?!\d+)(\w+)/g, (match) => requestParams[match.substring(1)])
}
