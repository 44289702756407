import { IParametersServices } from 'interfaces'
import { endpointCaller } from '../endpointCaller'
import { IAgendaRepository } from 'pages/Agenda/interfaces'

interface IAgendaServices extends IAgendaRepository, IParametersServices {}

export const agendaRepository = {
  getList: async (parameters: IAgendaServices, pagination: IParametersServices) => {
    const response = await endpointCaller<IAgendaServices>({
      method: 'GET',
      parameters,
      uri: 'Agenda/:codigoProfesional',
      pagination
    })
    return response
  }
}
