import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import AppProviders from './Providers'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const App = React.lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <ConfigProvider>
      <AppProviders>
          <React.StrictMode>
            <App />
          </React.StrictMode>
      </AppProviders>
    </ConfigProvider>
  </BrowserRouter>
)

serviceWorkerRegistration.register({
  onSuccess: () => console.log('Service Worker registrado correctamente.'),
  onUpdate: async (registration) => {
    try {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      }
    } catch (error) {
      console.error('Error actualizando el Service Worker:', error)
    }
  },
})
