import React, { createContext, useContext, useState } from 'react'
import { AuthContext } from '../../../interfaces/'
import { UserInfo } from '../../../interfaces/authContext'

interface IAuthContext {
  children: React.ReactNode
}

export const AuthLoginContext = createContext<AuthContext>(undefined!)

export const useAuthentication = () => useContext(AuthLoginContext)

export const AuthContextProvider = (props: IAuthContext) => {
  const { children } = props
  const [token, setToken] = useState<string | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo)

  const loginHandler = (token : string | null) => {
    setToken(token)
    setIsLoggedIn(true)
  }
  const logoutHandler = () => {
    setToken(null)
  }
  const userInfoHandler = (value: UserInfo) => {
    setUserInfo(value)
  }
  const valirSessionUser = () => {
    const tokenValid = localStorage.getItem('token')
    if (tokenValid) {
      const info = localStorage.getItem('userInfo')
      loginHandler(localStorage.getItem('token'))
      userInfoHandler(!info ? {} : JSON.parse(info))
    }
  }
  const contextValue: AuthContext = {
    token,
    isLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    valirSessionUser,
    userInfoHandler,
    userInfo
  }
  return (
    <AuthLoginContext.Provider value={contextValue}>{children}</AuthLoginContext.Provider>
  )
}
