import { agendaRepository } from './agendaRepository'
import { patientsRepository } from './patientsRepository'
import { progressPatientRepository } from './progressPatientRepository'
import { useRepository } from './provider'
import { remitosRepository } from './remitosRepository'

export { useRepository }

export const repository = {
  remitos: remitosRepository,
  agenda: agendaRepository,
  patients: patientsRepository,
  progressPatient: progressPatientRepository
}
