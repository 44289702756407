import { IProgressPatient, IProgressPatientDelete, IProgressPatientPost, IProgressPatientRequest } from '../../pages/ProgressPatients/interfaces'
import { endpointCaller } from '../endpointCaller'
import { IParametersServices } from 'interfaces'

export const progressPatientRepository = {
  getList: async (parameters: IProgressPatientRequest, pagination: IParametersServices) => {
    const response = await endpointCaller({
      method: 'GET',
      uri: 'evoluciones',
      parameters,
      pagination
    })

    return response
  },
  put: async (data: IProgressPatient) => {
    const response = await endpointCaller({
      method: 'PUT',
      uri: 'evoluciones',
      data
    })
    return response
  },
  delete: async (parameters: IProgressPatientDelete) => {
    const response = await endpointCaller({
      method: 'DELETE',
      uri: 'evoluciones',
      parameters
    })

    return response
  },
  post: async (data: IProgressPatientPost) => {
    const response = await endpointCaller({
      method: 'POST',
      uri: 'evoluciones',
      data
    })

    return response
  }
}
