/* eslint-disable no-restricted-globals */

// Constantes y configuración
const LOCALHOST_PATTERNS = ['localhost', '[::1]']
const SW_VERSION = '1.2.1'

const isLocalhost = Boolean(
  LOCALHOST_PATTERNS.includes(window.location.hostname) ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

// Función auxiliar para logging de errores
function logError (context, error) {
  console.error(`[Service Worker][${context}]`, error)
}

// Registro del Service Worker
export function register (config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      return // No registra si las URLs de origen no coinciden.
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        handleServiceWorker(swUrl, config, true)
        navigator.serviceWorker.ready.then(() => {
          console.log('Service Worker activo en localhost')
        })
      } else {
        handleServiceWorker(swUrl, config)
      }
    })
  } else {
    console.warn('El navegador no soporta Service Workers o no está en producción.')
  }
}

// Manejo general del Service Worker
function handleServiceWorker (swUrl, config, isLocal = false) {
  fetchWithTimeout(swUrl, { timeout: 5000, headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      if (
        response.status === 404 ||
        response.headers.get('content-type')?.indexOf('javascript') === -1
      ) {
        unregisterSW()
      } else {
        registerValidSW(swUrl, config)
      }
    })
    .catch((error) => logError(isLocal ? 'Verificación SW en localhost' : 'Verificación SW', error))
}

// Registro del SW válido
function registerValidSW (swUrl, config) {
  navigator.serviceWorker
    .register(swUrl, { scope: '/', version: SW_VERSION })
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('Nueva versión disponible:', SW_VERSION)
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              console.log('SW instalado por primera vez:', SW_VERSION)
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => logError('Registro SW', error))
}

// Anulación del registro del SW
function unregisterSW () {
  navigator.serviceWorker.ready
    .then((registration) => registration.unregister())
    .then(() => window.location.reload())
    .catch((error) => logError('Unregister SW', error))
}

// Función para manejar timeouts de fetch
function fetchWithTimeout (resource, options = {}) {
  const { timeout = 5000 } = options

  return Promise.race([
    fetch(resource, options),
    new Promise((resolve, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    ),
  ])
}

// Anulación manual del SW
export function unregister () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => logError('Manual Unregister SW', error))
  }
}
