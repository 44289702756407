import { endpointCaller } from '../endpointCaller'
import { IParametersServices } from 'interfaces'
import { IRemitosRequest } from 'pages/Remitos/interfaces'

export const remitosRepository = {
  getList: async (parameters: IRemitosRequest, pagination: IParametersServices) => {
    const response = await endpointCaller({
      method: 'GET',
      uri: 'pacientes/:codigoPaciente/remitos',
      parameters,
      pagination
    })

    return response
  }
}
