import React, { createContext, useContext } from 'react'
import { repository } from '.'

export type RepositoryModel = typeof repository

const RepositoryContext = createContext<RepositoryModel>(undefined!)

export const useRepository = function<TRepository> (
  mapContext: (context: typeof repository) => TRepository
): TRepository {
  const context = useContext(RepositoryContext)
  return mapContext(context)
}

export const RepositoryProvider = (props: {
    repository: RepositoryModel
    children?: React.ReactNode
}) => {
  return (
        <RepositoryContext.Provider value={props.repository}>
            {props.children}
        </RepositoryContext.Provider>
  )
}
