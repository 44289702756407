import { IParametersServices } from 'interfaces'
import { patientsListRequest } from 'interfaces/patients/patients'
import { endpointCaller } from 'services/endpointCaller'

export const patientsRepository = {
  getById: async (id: number) => {
    const response = await endpointCaller<{}>({
      method: 'GET',
      parameters: { id },
      uri: 'pacientes/:id',
    })
    return response
  },
  getList: async (parameters: patientsListRequest, pagination: IParametersServices) => {
    const response = await endpointCaller<{}>({
      method: 'GET',
      parameters,
      uri: 'pacientes',
      pagination
    })
    return response
  }
}
